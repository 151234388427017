import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/repository/src/layouts/PageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Corporate Enforcement Agent Services Contract - Local Taxation/Corporate Debt`}</h2>
    <p>{`Rotherham Metropolitan Council undertook a comprehensive formal OJEU procurement exercise to appoint
Enforcement Agents to undertake Debt Recovery and Enforcement of Local taxation and all corporate
debts outstanding to the authority.`}</p>
    <h2>{`A detailed 3 stage process was conducted as follows:`}</h2>
    <ul>
      <li parentName="ul">{`Pre-Qualification Questionnaire`}</li>
      <li parentName="ul">{`Invitation to Tender`}</li>
      <li parentName="ul">{`Presentation and Interviews`}</li>
    </ul>
    <p>{`A framework was established of up to 6 suppliers.`}</p>
    <p>{`The Council had structured the framework to allow any Local Authority to use and formally procure
services, either through a direct call off by order of supplier ranking or through a further mini
competition.`}</p>
    <p>{`Using the Rotherham framework to select suppliers for your service significantly reduces costs
involved in the process, greatly reduces administration and creates efficiency.`}</p>
    <p>{`The framework can be tailored to your local and specific requirements and you can set any contract
length appropriate.`}</p>
    <p>{`More and more Local Authorities are choosing this route to meet audit and procurement requirements
in their supplier selection process, finding the established framework in line with their own
procurement rules and flexible enough to tailor to local requirements.`}</p>
    <p>{`For more information on how to access this framework, please contact
`}<a parentName="p" {...{
        "href": "mailto:sales@jacobsenforcement.com"
      }}>{`sales@jacobsenforcement.com`}</a>{` or telephone the team on
`}<a parentName="p" {...{
        "href": "tel:01516504979"
      }}>{`0151 650 4979`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      